.jarallax {
position: relative;
z-index: 0;
background-size: cover;
}

.ja-container {
  position: absolute;
  top: 0px;left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
  clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
  background-attachment: fixed;
}

.jarallax-container-1 {
  background-image: url(/public/images/background/7.jpg);
}

.jarallax-container-2 {
  background-image: url(/public/images/background/4.jpg);
}

/* .jarallax-img {
  object-fit: cover;
  object-position: 50% 50%;
  max-width: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 1083.2px;
  height: 744.594px;
  overflow: hidden;
  pointer-events: none;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, opacity;
  margin-top: -10.7969px;
  transform: translate3d(0px, 106.247px, 0px);
} */
